<template>
  <b-card no-body class="private-image-card">
    <b-card-header>
      <img
        v-if="extendedUser.hasCover"
        :src="baseUrl + extendedUser.getCover.path"
        alt="cover-image"
        class="h-100 w-100 mt-0"
        height="176" />
      <img
        v-else src="../../assets/img/profile-background.png"
        alt="cover-image"
        class="h-100 w-100 mt-0"
        height="176" />
    </b-card-header>
    <b-card-body>
      <div class="avatar-container d-flex align-items-end">
        <img
          v-if="extendedUser.hasAvatar"
          :src="baseUrl + extendedUser.getAvatar.path"
          alt="avatar"
          class="user-avatar img-thumbnail img-fluid"/>
        <img
          v-else src="/img/default-user-avatar.svg"
          alt="avatar"
          class="user-avatar img-thumbnail img-fluid"/>
      </div>
      <div class="user-info-container mt-3">
        <b-card-title>{{ user.getFirstName | defaultValue }} {{ user.getLastName | defaultValue }}</b-card-title>
        <p class="nickname mb-1">{{ user.getNickname }}</p>
        <p v-if="extendedUser.hasLocation" class="mb-1">
          <img src="../../assets/icons/icon-location-input.svg" alt="locations-input">
          {{ extendedUser.getCompleteLocation }}</p>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: 'ResumePrivateImageCard',
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  props: {
    user: Object,
    extendedUser: Object,
    eventName: String,
    editIcon: {
      type: Boolean,
      default: true
    },
    modalId: String
  }
}
</script>

<style lang="less">
  @import 'privateImageCard.less';

  .nickname {
    font-size: 16px;
    font-weight: 300;
  }
</style>
