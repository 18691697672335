<template>
  <div id="private-technician">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="private-technician">
      <b-row class="mb-3">
        <b-col>
          <b-link :to="{ name: 'community' }" class="btn btn-link">
            Volver a los resultados de búsqueda
          </b-link>
        </b-col>
      </b-row>
      <b-row v-if="!isLoadingData">
        <b-col cols="12" sm="12" md="6" lg="4" class="d-none d-lg-block">
          <resume-private-image-card :editable="false" :user="user" :extendedUser="technician" :showRoles="false"></resume-private-image-card>
          <languages-card :editable="false" :userLanguages="technician.getLanguages" urlTitleIcon="icon-languages.svg"></languages-card>
          <related-profiles-card :editable="false" @onClickSimilarProfile="onClickSimilarProfile"></related-profiles-card>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="8" class="d-none d-lg-block">
          <SendPrivateMessage :to-user="user" :openedMessageBox="openedMessageBox"/>
          <about-me-card :editable="false" :user="user" :extendedUser="technician" urlTitleIcon="icon-about-me.svg"></about-me-card>
          <professional-profile-card :editable="false" :user="user" :extendedUser="technician" urlTitleIcon="icon-professional-profile.svg"></professional-profile-card>
          <certification-card :editable="false" :userCertifications="technician.getCertifications" urlTitleIcon="icon-licenses.svg"></certification-card>
          <jobs-card :editable="false" :userJobs="technician.getJobs" urlTitleIcon="icon-jobs.svg"></jobs-card>
          <hardware-card :editable="false" :userHardware="technician.getHardware" urlTitleIcon="icon-hardware.svg"></hardware-card>
        </b-col>

        <b-col cols="12" sm="12" md="6" class="d-block d-lg-none">
          <resume-private-image-card :editable="false" :user="user" :extendedUser="technician"></resume-private-image-card>
          <SendPrivateMessage :to-user="user" :openedMessageBox="openedMessageBox"/>
          <about-me-card :editable="false" :user="user" :extendedUser="technician" urlTitleIcon="icon-about-me.svg"></about-me-card>
          <professional-profile-card :editable="false" :user="user" :extendedUser="technician" urlTitleIcon="icon-professional-profile.svg"></professional-profile-card>
          <certification-card :editable="false" :userCertifications="technician.getCertifications" urlTitleIcon="icon-licenses.svg"></certification-card>
          <languages-card :editable="false" :userLanguages="technician.getLanguages" urlTitleIcon="icon-languages.svg"></languages-card>
          <jobs-card :editable="false" :userJobs="technician.getJobs" urlTitleIcon="icon-jobs.svg"></jobs-card>
          <hardware-card :editable="false" :userHardware="technician.getHardware" urlTitleIcon="icon-hardware.svg"></hardware-card>
          <related-profiles-card :editable="false" @onClickSimilarProfile="onClickSimilarProfile"></related-profiles-card>
        </b-col>
      </b-row>
    </b-container>
    <confirmation-send-message-modal></confirmation-send-message-modal>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import AboutMeCard from '../components/AboutMe/AboutMeCard'
import ProfessionalProfileCard from '../components/ProfessionalProfile/ProfessionalProfileCard'
import CertificationCard from '../components/Certification/CertificationCard'
import LanguagesCard from '../components/Languages/LanguagesCard'
import RelatedProfilesCard from '../components/RelatedProfiles/RelatedProfilesCard'
import ResumePrivateImageCard from '../components/Card/ResumePrivateImageCard'
import SendPrivateMessage from '../components/PrivateMessage/SendPrivateMessage'
import ConfirmationSendMessageModal from '../components/Modal/ConfirmationSendMessageModal'
import JobsCard from '../components/Jobs/JobsCard.vue'
import HardwareCard from '../components/Hardware/HardwareCard.vue'

export default {
  name: 'PrivateUserProfile',
  components: {
    ConfirmationSendMessageModal,
    SendPrivateMessage,
    ResumePrivateImageCard,
    RelatedProfilesCard,
    LanguagesCard,
    CertificationCard,
    ProfessionalProfileCard,
    AboutMeCard,
    Header,
    JobsCard,
    HardwareCard
  },
  data () {
    return {
      user: {},
      isLoadingData: true,
      sendMessage: false,
      message: null
    }
  },
  props: {
    openedMessageBox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    technician () {
      return this.user.getTechnician
    }
  },
  created: async function () {
    this.lockScreen()
    this.user = await this.$store.dispatch('getUserByUUID', this.$route.params.id)
    this.isLoadingData = !this.isLoadingData
    this.unlockScreen()
  },
  methods: {
    async onClickSimilarProfile (relatedProfileId) {
      this.lockScreen()
      this.user = await this.$store.dispatch('getUserByUUID', relatedProfileId)
      this.$router.replace({ name: 'private-technician-profile', params: { id: this.user.getId } })
    }
  }
}
</script>

<style lang="less">
  .private-technician {
    margin-top: 16px;
    text-align: left;
  }
</style>
