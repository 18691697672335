export default class PrivateMessage {
  constructor (privateMessageData) {
    this.to = privateMessageData.to
    this.message = privateMessageData.message
  }

  get getTo () {
    return this.to
  }

  get getMessage () {
    return this.message
  }
}
