import PrivateMessage from '../models/PrivateMessage'

class PrivateMessageFactory {
  getPrivateMessage (toUser, message) {
    const privateMessage = {
      to: toUser,
      message: message
    }

    return new PrivateMessage(privateMessage)
  }
}

export default new PrivateMessageFactory()
