<template>
  <div>
    <a href="javascript:void(0)" class="btn btn-primary w-100 mb-3" @click="sendMessage = !sendMessage" v-if="!sendMessage">Contactar ahora</a>
    <private-card
      title="Enviar mensaje"
      :editIcon="false"
      v-if="sendMessage"
    >
      <template v-slot:private-card-body>
        <validation-observer ref="sendMessageForm" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSendPrivateMessage)" autocomplete="off" novalidate>
            <b-row>
              <b-col>
                <validation-provider vid="mensaje" name="mensaje" rules="required" v-slot="validationContext">
                  <b-form-group id="message" label-for="message-textarea">
                    <b-form-textarea
                      id="message-textarea"
                      name="message-textarea"
                      v-model="message"
                      :state="getValidationState(validationContext)"
                      placeholder="Escribe el mensaje que quieras enviar al usuario"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-button type="submit" variant="primary" class="w-100">Enviar</b-button>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </private-card>
  </div>
</template>

<script>

import PrivateCard from '../Card/PrivateCard'
import PrivateMessageFactory from '../../domain/services/PrivateMessageFactory'

export default {
  name: 'SendPrivateMessage',
  components: { PrivateCard },
  props: {
    toUser: {
      type: Object,
      required: true
    },
    openedMessageBox: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      sendMessage: this.openedMessageBox || false,
      message: null
    }
  },
  methods: {
    onSendPrivateMessage () {
      const privateMessage = PrivateMessageFactory.getPrivateMessage(
        this.toUser.getId,
        this.message
      )
      this.lockScreen()
      this.$store.dispatch('sendPrivateMessage', privateMessage)
        .then(() => {
          this.message = null
          this.unlockScreen()
          this.sendMessage = !this.sendMessage
          this.$bvModal.show('avify-confirmation-send-message-modal')
        })
        .catch(() => {
          this.sendMessage = !this.sendMessage
        })
    }
  }
}
</script>
