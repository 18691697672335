import { render, staticRenderFns } from "./RelatedProfilesCard.vue?vue&type=template&id=3d3067ba&"
import script from "./RelatedProfilesCard.vue?vue&type=script&lang=js&"
export * from "./RelatedProfilesCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports