<template>
  <b-modal
    id="avify-confirmation-send-message-modal"
    centered
    modal-class="avify-confirmation-modal"
    :hide-header="true"
    size="xs"
  >
    <div class="d-flex flex-column">
      <img src="../../assets/icons/icon-confirmation.svg" class="img-fluid" alt="Confirmation image">
      Mensaje enviado con éxito
    </div>
    <template v-slot:modal-footer="{ hide }">
      <b-button variant="primary" size="xl" @click="hide()">Continuar</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmationSendMessageModal'
}
</script>

<style lang="less">
  .avify-confirmation-modal {
    .modal-xs {
      @media (min-width: 576px) {
        max-width: 400px;
      }
    }

    .modal-content {
      background-color: #31395A;
      border-bottom: none;
    }

    .modal-body {
      text-align: center;
      color: white;
    }

    .modal-footer {
      border-top: none;
      justify-content: center;
    }
  }
</style>
