<template>
  <div class="related-profile-row" @click="$emit('onClickSimilarProfile', relatedProfile.id)">
    <b-media vertical-align="center">
      <template v-slot:aside>
        <b-img :src="getAvatarUrl" blank-color="#ccc" width="48" height="48" alt="placeholder"></b-img>
      </template>
      <p class="mt-0 mb-1 related-profile-row-nickname">{{ relatedProfile.getNickname }}</p>
      <p class="mb-0 text-l">{{ relatedProfile.getFirstName | defaultValue }} {{ relatedProfile.getLastName | defaultValue }}</p>
    </b-media>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'RelatedProfileRow',
  props: {
    relatedProfile: {
      type: Object
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_IMAGE_API_URL
    }
  },
  computed: {
    getAvatarUrl () {
      if (this.relatedProfile.hasAvatar) {
        return this.baseUrl + this.relatedProfile.getAvatar.path
      }

      return '/img/default-user-avatar.svg'
    }
  }
}
</script>

<style lang="less">
  @import "relatedProfileRow";
</style>
