<template>
  <private-card
    title="Estos perfiles también corresponden a tu búsqueda"
    :editIcon="editable"
    v-if="filteredUsers.length > 0"
  >
    <template v-slot:private-card-body>
      <related-profile-row
        v-for="relatedProfile in filteredUsers"
        :key="relatedProfile.id"
        :relatedProfile="relatedProfile"
        @onClickSimilarProfile="onClickSimilarProfile"
      ></related-profile-row>
    </template>
  </private-card>
</template>

<script>
import PrivateCard from '../Card/PrivateCard'
import RelatedProfileRow from './RelatedProfileRow'
export default {
  name: 'RelatedProfilesCard',
  components: { RelatedProfileRow, PrivateCard },
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    loggedUserId () {
      return this.$store.getters.loggedUser.getId
    },
    filteredUsers () {
      const relatedProfiles = this.$store.getters.filteredUsers
      if (relatedProfiles.length === 0) {
        return this.$store.dispatch('findCommunityResults')
      }

      const filteredRelatedProfiles = relatedProfiles.slice(0, 4).filter(element => (this.loggedUserId !== element.getId && this.$route.params.id !== element.getId))

      if (filteredRelatedProfiles.length === 4) {
        filteredRelatedProfiles.pop()
      }

      return filteredRelatedProfiles
    }
  },
  methods: {
    onClickSimilarProfile (similarProfileId) {
      this.$emit('onClickSimilarProfile', similarProfileId)
    }
  }
}
</script>
